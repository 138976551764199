import Link from 'next/link';
import IconButton from '@mui/material/IconButton';
import Image from 'components/FutureImage';

export default function SocialProof({ referrer, userCount }) {
  const featuredUsers = [
    'mibreit-photo',
    'wanderein',
    'for91days',
    'invisusmundi',
    'koenau',
    'juancar347',
    'adambarratt',
    referrer || 'dresden.theone',
  ];
  return (
    <div className="lg:tw-flex tw-pb-8">
      <div className="tw-flex -tw-space-x-3 tw-justify-center">
        {featuredUsers.map(user => (
          <Link href={`/@${user}`} prefetch={false} key={user}>
            <IconButton className="tw-p-0" size="large">
              <Image
                priority
                src={`/avatar/${user}/small`}
                className="tw-border-solid tw-rounded-full tw-border-2 tw-border-white hover:tw-opacity-90"
                alt={user}
                width={46}
                height={46}
                unoptimized
              />
            </IconButton>
          </Link>
        ))}
      </div>
      <div className="lg:tw-pl-4 tw-text-white tw-shrink-0 tw-pt-2 lg:tw-pt-0 tw-flex">
        <div className="m-auto">
          <span>
            Join{' '}
            <span className="tw-inline-block">
              {userCount ? Math.floor(userCount / 1000) : '4'}k+ bloggers
            </span>
          </span>{' '}
          <span className="tw-inline lg:tw-block">using TravelFeed</span>
        </div>
      </div>
    </div>
  );
}
