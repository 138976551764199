import {
  faBolt,
  faMoneyBillWave,
  faPencilAlt,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, withStyles } from 'tss-react/mui';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'components/Image';
import Link from 'next/link';
import React from 'react';
import easyearnImg from '../../../images/easyearn.svg';
import easyeditorImg from '../../../images/easyeditor.svg';
import easyspeedImg from '../../../images/easyspeed.svg';

const features = [
  {
    title: 'Speed',
    longTitle: 'Over 19x Faster than Wordpress',
    text:
      'Unlike most other blogging tools that use slow and outdated technology, we use a modern system that makes your blog super fast and accessible from anywhere in the world. We do this by using a powerful global network that helps deliver your blog to your readers quickly and easily.',
    featureList: [
      'Zero configuration - we do all optimizations automatically',
      'Ultra fast access times from every corner of the world',
      'Never waste time updating plugins, themes or the database',
    ],
    image: easyspeedImg,
    icon: <FontAwesomeIcon icon={faBolt} />,
    externalHref: 'https://wanderein.com',
    linkText: 'TRY THE DEMO',
  },
  {
    title: 'Editor',
    longTitle: 'Create Beautiful Posts without Coding Skills',
    text:
      'AI Blogger transforms your notes into drafts effortlessly. Fine-tune these drafts in EasyEditor, our block-based editor, and utilize powerful custom blocks like:',
    featureList: [
      'Table of Contents',
      'Photo galleries',
      'Embed Instagram, YouTube, Google Maps and many more',
    ],
    image: easyeditorImg,
    icon: <FontAwesomeIcon icon={faPencilAlt} />,
    learnMoreHref: '/ai',
    linkText: 'TRY AI BLOGGER',
  },
  {
    title: 'Earn',
    longTitle: 'Earning Through Blogging Made Simple',
    text: `TravelFeed is your gateway to monetizing your travel content. We offer multiple avenues to earn, all without the technical jargon or complexities. From affiliate partnerships to display ads, we've got you covered.`,
    featureList: [
      'Set up your earning methods once',
      'We handle the background processes, ensuring seamless integration',
      `Your content's value is maximized with our monetization strategies`,
    ],
    image: easyearnImg,
    icon: <FontAwesomeIcon icon={faMoneyBillWave} />,
    learnMoreHref: '/how-to-make-money-with-a-travel-blog',
    linkText: 'START EARNING',
  },
];

const StyledTabs = withStyles(
  props => (
    <Tabs
      textColor="primary"
      {...props}
      TabIndicatorProps={{ children: <span /> }}
    />
  ),
  {
    root: {},
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        width: '100%',
        backgroundColor: '#00695C',
      },
    },
  },
);

const StyledTab = withStyles(
  props => <Tab disableRipple {...props} />,
  theme => ({
    root: {
      borderBottom: '2px solid rgb(202, 202, 202)',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      textTransform: 'none',
      '&:focus': {
        opacity: 1,
      },
    },
  }),
);

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
}));

export default function FeatureTabs() {
  const { classes } = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobileView = useMediaQuery('(max-width: 800px)');

  return (
    <>
      <div id="features" className="tabs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="above-heading">Travel Blogging for Everyone</div>
              <h2 className="h2-heading">TravelFeed is Easy</h2>
              <p className="p-heading">
                With TravelFeed, you don&apos;t need to be a web developer, SEO
                pro or marketing expert to have a successful travel blog. Focus
                on what you do best: Creating amazing travel content.
              </p>
            </div>
          </div>
          <div className={classes.root}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
              centered
            >
              {features.map((props, i) => {
                const { title, icon } = props;

                return (
                  <StyledTab
                    key={title}
                    label={
                      <div
                        className="row"
                        style={value === i ? { color: '#00695C' } : undefined}
                      >
                        <div className="col p-1">{icon}</div>
                        <div className="col p-1">
                          {isMobileView ? '' : 'Easy'}
                          {title}
                        </div>
                      </div>
                    }
                    className="nav-link"
                  />
                );
              })}
            </StyledTabs>
            <div className="pt-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="image-container">
                    <Image
                      className="img-fluid"
                      src={features[value].image}
                      alt="alternative"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-container">
                    <h3>{features[value].longTitle}</h3>
                    <p>{features[value].text}</p>
                    <ul className="list-unstyled li-space-lg">
                      {features[value].featureList.map(item => {
                        return (
                          <li className="media" key={item}>
                            <FontAwesomeIcon
                              className="pl-1 pt-2"
                              icon={faSquare}
                              color="#00695C"
                            />
                            <p className="media-body">{item}</p>
                          </li>
                        );
                      })}
                      <div className="pt-4">
                        {features[value].externalHref && (
                          <a
                            className="btn-solid-reg popup-with-move-anim"
                            href={features[value].externalHref}
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                          >
                            {features[value].linkText}
                          </a>
                        )}
                        {features[value].learnMoreHref && (
                          <Link
                            href={features[value].learnMoreHref}
                            className="btn-solid-reg popup-with-move-anim"
                          >
                            {features[value].linkText}
                          </Link>
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Typography className={classes.padding} />
          </div>
        </div>
      </div>
    </>
  );
}
