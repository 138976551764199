import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const features = [
  'Publish on TravelFeed',
  'Cross-Post to Hive',
  <>
    Use your Own Domain<sup>1</sup>
  </>,
  'Design Your Own Blog',
  'Free SSL & CDN',
  'Auto Image Optimization',
  'Monthly AI Credits',
  'Ad-Free',
  'Interactive World Map',
  'Destinations Widget',
  'Dark Mode',
  'Search',
  <>Analytics</>,
  <>
    Newsletter Form <sup>[Soon]</sup>
  </>,
  <>
    Multiple Authors <sup>[Soon]</sup>
  </>,
  'API access',
  'Dedicated Support',
];

export default function PricingTableItem({
  isYearly,
  plan,
  description,
  featureNo,
  isPopular,
  isFree,
  monthly,
  yearly,
  aiCredits,
  discount,
}) {
  return (
    <>
      <div className="col-lg-3">
        <div className={'pricing-card' + (isPopular ? ' active' : '')}>
          <div className="card-head">
            <h6>{plan}</h6>
            <p>
              <span>
                <span className="small-title">
                  {isFree ? (
                    'For our community'
                  ) : discount !== 1 ? (
                    <>
                      <span className="tw-line-through">
                        $ {isYearly ? yearly / 12 : monthly}
                      </span>
                      <span> per month</span>
                    </>
                  ) : (
                    'Per month'
                  )}
                </span>
              </span>
            </p>
            <h2>
              {isFree ? (
                <span className="small-title">Free Forever</span>
              ) : (
                <span className="small-title">
                  $
                  {discount !== 1
                    ? isYearly
                      ? Math.round((yearly / 12) * discount * 10) / 10
                      : Math.round(monthly * discount * 10) / 10
                    : isYearly
                    ? yearly / 12
                    : monthly}
                  {discount !== 1 ? <sup>*</sup> : <></>}
                </span>
              )}
            </h2>
            <p>{description}</p>
          </div>

          <div className="card-content">
            <ul className="pricing-list">
              {features.map((feature, i) => {
                return (
                  <li key={feature}>
                    <span>
                      {i > featureNo - 1 ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="#b91c1c"
                          className="mr-2"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#00695C"
                          className="mr-2"
                        />
                      )}
                    </span>
                    {feature === 'Monthly AI Credits' && aiCredits
                      ? `${aiCredits} `
                      : ''}
                    {feature}
                  </li>
                );
              })}
            </ul>
            <div className="card-btn">
              <a className="btn-solid-reg page-scroll" href="#join">
                {isFree ? 'GET STARTED' : 'TRY FOR FREE'}
              </a>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .pricing-card {
          border-top: 5px solid transparent;
          border-radius: 6px;
          padding: 20px 20px 25px;
          background-color: #fff;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          margin-bottom: 30px;
        }

        .pricing-card .card-head {
          padding-bottom: 18px;
        }

        .pricing-card .card-head h6 {
          color: #666666;
          font-family: 'Karla', sans-serif;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 0.43px;
          line-height: 32px;
          text-transform: uppercase;
          margin-bottom: 22px;
        }

        .pricing-card .card-head h2 .small-title {
          color: #1f1f1f;
          font-family: 'Karla', sans-serif;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: -1.2px;
        }

        .pricing-card .card-head h2 .big-txt {
          color: #1f1f1f;
          font-family: 'Karla', sans-serif;
          font-size: 50px;
          font-weight: 700;
          letter-spacing: -2px;
          line-height: 60px;
        }

        .pricing-card .card-head h2 .big-txt:before {
          position: absolute;
        }

        .pricing-card .card-head h2 .month {
          color: #1f1f1f;
          font-family: 'Karla', sans-serif;
          font-size: 17px;
          font-weight: 700;
          letter-spacing: -0.68px;
        }

        .pricing-card .card-head p {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.6px;
          line-height: 28px;
          color: #666666;
          margin-bottom: 0;
        }

        .pricing-list {
          list-style: none;
          padding-left: 5px;
        }

        .pricing-card .card-content .pricing-list li {
          color: #1f1f1f;
          font-family: 'Karla', sans-serif;
          font-size: 17px;
          font-weight: 400;
          letter-spacing: -0.42px;
          line-height: 32px;
          padding: 2px 0;
        }

        .pricing-card .card-content .pricing-list li.disable-opt {
          text-decoration: line-through;
          color: #8e8e8e;
        }

        .pricing-card .card-content p {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.6px;
          line-height: 28px;
          color: #666666;
          margin-bottom: 0;
        }

        .pricing-card .card-badge {
          padding: 0 5px;
          position: absolute;
          top: 30px;
          right: 45px;
          background-color: #f46f7c;
          border-radius: 500px;
          color: #fff;
          text-align: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }

        @media (min-width: 992px) {
          .pricing-card .card-badge {
            width: 120px;
          }
        }

        @media (min-width: 480px) {
          .pricing-card .card-badge {
            width: 120px;
          }
        }

        @media (min-width: 768px) {
          .pricing-card .card-badge {
            width: 120px;
          }
        }

        @media (min-width: 1200px) {
          .pricing-card .card-badge {
            width: 120px;
          }
        }

        .pricing-card .card-btn {
          -webkit-transition: 0.4s;
          transition: 0.4s;
          padding-top: 33px;
          padding-bottom: 5px;
          margin-left: 1px;
        }

        .pricing-card.active {
          border-top: 5px solid #00a893;
        }
      `}</style>
    </>
  );
}
