/* eslint-disable jsx-a11y/label-has-associated-control */
import Image from 'components/Image';
import React, { useState } from 'react';
import DividerLanding from '../../../images/landing-2-shape.svg';
import PricingTableItem from './PricingTableItem';

export default function PricingTable({ referrer }) {
  const [isYearly, setIsYearly] = useState(true);

  const discount = referrer === 'safetywing' ? 0.7 : 1;

  return (
    <>
      <section className="pricing-section">
        <div className="shape">
          <div className="frameImg">
            <Image src={DividerLanding} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 id="pricing">Choose your Plan</h2>
                <p>
                  Our mission is to make travel blogging made accessible and
                  affordable for every traveler. This is why we cover all costs
                  to get started in our free forever community plan. Upgrade to
                  a paid plan at any time to get your own blog on your own
                  domain.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="btn--toggle-pricing"
                data-tab-target="#pricing-tab-7"
              >
                <p>Monthly</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isYearly}
                    onClick={e => setIsYearly(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <p>Yearly</p>
              </div>
            </div>
          </div>
          <div className="custom-tab-wrapper monthly" id="pricing-tab-7">
            <div className="row mb-d-30">
              <PricingTableItem
                isYearly={isYearly}
                plan="Community"
                description="Great for hobby bloggers"
                featureNo={2}
                isFree
              />
              <PricingTableItem
                discount={discount}
                isYearly={isYearly}
                plan="Basic"
                description="Great for new bloggers"
                featureNo={8}
                monthly={19}
                yearly={180}
                aiCredits="100k"
              />
              <PricingTableItem
                discount={discount}
                isYearly={isYearly}
                plan="Pro"
                description="Great for professional bloggers"
                features={[
                  'Publish your Posts on TravelFeed',
                  'Earn Crypto with Cross-Posting',
                ]}
                isPopular={true}
                featureNo={14}
                monthly={29}
                yearly={300}
                aiCredits="1M"
              />
              <PricingTableItem
                discount={discount}
                isYearly={isYearly}
                plan="VIP"
                description="Great for big blogs"
                features={[
                  'Publish your Posts on TravelFeed',
                  'Earn Crypto with Cross-Posting',
                ]}
                featureNo={17}
                monthly={49}
                yearly={540}
                aiCredits="2M"
              />
            </div>
          </div>
          <div className="p-footnotes">
            {discount !== 1 ? (
              <p>
                <sup>*</sup> Get 30% off your first year! Use code{' '}
                {referrer.toUpperCase()} at checkout. Regular pricing applies
                after the first year.
              </p>
            ) : (
              <></>
            )}
            <p>
              <sup>1</sup> yourname.truvvl.blog subdomain included or connect
              your own external domain
            </p>
            <p>All prices in USD</p>
          </div>
        </div>
      </section>
      <style jsx>{`
        .section-title {
          margin-bottom: 5px;
        }

        .section-title h2 {
          line-height: 60px;
          font: 700 2rem/2.625rem 'Open Sans', sans-serif;
          letter-spacing: -0.2px;
        }

        .section-title p {
          font: 400 1rem/1.625rem 'Open Sans', sans-serif;
          padding-top: 20px;
        }

        @media (min-width: 992px) {
          .section-title {
            margin-bottom: 10px;
          }
        }

        /* ///////////////////////////
      Content Sections
      //////////////////////////// */

        /* ///////////////////////////
      Pricing Section 
      //////////////////////////// */
        .pricing-section {
          padding-top: 45px;
          padding-bottom: 68px;
          background-color: #30393b;
          position: relative;
        }

        .pricing-section .shape {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          -webkit-transform: translateY(-85%);
          transform: translateY(-85%);
        }

        .pricing-section .section-title {
          padding-bottom: 0px;
        }

        .pricing-section .section-title h2 {
          color: #fff;
        }

        .pricing-section .section-title p {
          color: #878b90;
        }

        @media (min-width: 992px) {
          .pricing-section {
            padding-top: 80px;
            padding-bottom: 120px;
          }
          .pricing-section .section-title {
            padding-bottom: 20px;
          }
        }

        .btn--toggle-pricing {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          height: 100%;
        }

        .btn--toggle-pricing p {
          color: #fff;
        }

        .btn--toggle-pricing p:last-child {
          padding-right: 0;
        }

        /* pricng toggle */
        .custom-tab-wrapper.monthly [data-monthly] {
          visibility: hidden;
        }

        .custom-tab-wrapper.monthly [data-monthly]:before {
          content: attr(data-monthly);
          visibility: visible;
          display: inline-block;
        }
        [class*='btn--toggle-'] .switch {
          position: relative;
          display: inline-block;
          width: 48px;
          height: 25px;
          margin-bottom: 0;
        }

        [class*='btn--toggle-'] .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        [class*='btn--toggle-'] .slider {
          position: absolute;
          cursor: pointer;
          top: 50%;
          left: 0;
          width: 100%;
          height: 100%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #fff;
          border: 1px solid #e5e5e5;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        [class*='btn--toggle-'] .slider:before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 5px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #1290a4;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        [class*='btn--toggle-'] input:checked + .slider {
          background-color: #fff;
          border: 1px solid #e5e5e5;
        }

        [class*='btn--toggle-'] input:focus + .slider {
          -webkit-box-shadow: 0 0 1px #fff;
          box-shadow: 0 0 1px #fff;
        }

        [class*='btn--toggle-'] input:checked + .slider:before {
          -webkit-transform: translateX(21px) translateY(-50%);
          transform: translateX(21px) translateY(-50%);
          background-color: #1290a4;
        }

        [class*='btn--toggle-'] .slider.round {
          border-radius: 34px;
        }

        [class*='btn--toggle-'] .slider.round:before {
          border-radius: 50%;
        }

        /* pricng toggle */
        .custom-tab-wrapper.monthly [data-monthly] {
          visibility: hidden;
        }

        .custom-tab-wrapper.monthly [data-monthly]:before {
          content: attr(data-monthly);
          visibility: visible;
          display: inline-block;
        }

        .custom-tab-wrapper.yearly [data-yearly] {
          visibility: hidden;
        }

        .custom-tab-wrapper.yearly [data-yearly]:before {
          content: attr(data-yearly);
          visibility: visible;
          display: inline-block;
        }

        [class*='btn--toggle-'] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding-bottom: 35px;
        }

        [class*='btn--toggle-'] p {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          padding: 0 12px;
          margin-bottom: 0;
        }

        [class*='btn--toggle-'] span {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.51px;
          text-transform: uppercase;
          color: #4eab08;
          background-color: rgba(78, 171, 8, 0.14);
          padding: 0 15px;
          border-radius: 5px;
        }

        [class*='btn--toggle-'] .badge--discount {
          height: 26px;
          line-height: 1;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0;
          text-align: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 83px;
        }

        [class*='btn--toggle-'] .switch {
          position: relative;
          display: inline-block;
          width: 48px;
          height: 25px;
          margin-bottom: 0;
        }

        [class*='btn--toggle-'] .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        [class*='btn--toggle-'] .slider {
          position: absolute;
          cursor: pointer;
          top: 50%;
          left: 0;
          width: 100%;
          height: 100%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #fff;
          border: 1px solid #e5e5e5;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        [class*='btn--toggle-'] .slider:before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 5px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #1290a4;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        [class*='btn--toggle-'] input:checked + .slider {
          background-color: #fff;
          border: 1px solid #e5e5e5;
        }

        [class*='btn--toggle-'] input:focus + .slider {
          -webkit-box-shadow: 0 0 1px #fff;
          box-shadow: 0 0 1px #fff;
        }

        [class*='btn--toggle-'] input:checked + .slider:before {
          -webkit-transform: translateX(21px) translateY(-50%);
          transform: translateX(21px) translateY(-50%);
          background-color: #1290a4;
        }

        [class*='btn--toggle-'] .slider.round {
          border-radius: 34px;
        }

        [class*='btn--toggle-'] .slider.round:before {
          border-radius: 50%;
        }

        .p-footnotes {
          font-size: 8px;
        }
      `}</style>
    </>
  );
}
