/* eslint-disable jsx-a11y/label-has-associated-control */
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import { teal } from '@mui/material/colors';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import Cookie from 'js-cookie';
import Image from 'components/Image';
import React, { useEffect, useState } from 'react';
import communityImg from '../../../images/community.svg';
import blogImg from '../../../images/earn-step-2.svg';
import earnImg from '../../../images/earnFromBlogging.svg';
import bottomFrame from '../../../images/joinBottomFrame.svg';
import frameImg from '../../../images/joinTopFrame.svg';
import travelImg from '../../../images/travel.svg';
import writeImg from '../../../images/write.svg';
import KeychainLogin from '../../Header/KeychainLogin';
import LoginServices from '../../Login/LoginServices';
import OnboardStart from '../../Onboarding/OnboardStart';
import FeatureTabs from './FeatureTabs';
import PricingTable from './PricingTable';
// import Video from './Video';
import SocialProof from './SocialProof';
import { GET_USER_COUNT } from '../../../helpers/graphql/userCount';
import { useQuery } from '@apollo/client';
import './joinstyles.css';

export default function Join(props) {
  const [referrer, setReferrer] = useState();
  const [hiveKeychain, setHiveKeychain] = useState(undefined);

  useEffect(() => {
    if (props.referrer) {
      Cookie.set('referrer', props.referrer);
      setReferrer(props.referrer);
    } else {
      setReferrer(Cookie.get('referrer'));
    }
  }, [props]);

  useEffect(() => {
    if (hiveKeychain === undefined && window && window.hive_keychain) {
      setHiveKeychain(true);
    }
  });

  const { data } = useQuery(GET_USER_COUNT);
  const userCount = data?.userCount || 4000;

  return (
    <div className="joinpage">
      <div className="bg-white">
        <header id="header" className="header">
          <div className="header-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-5">
                  <div className="text-container">
                    <h1>Start Your Own Travel Blog</h1>
                    <p className="p-large">
                      {referrer
                        ? `You've got amazing travel stories to share – and ${
                            referrer === 'safetywing' ? 'SafetyWing' : referrer
                          } thinks the world should hear them! Create your own blog in just one minute and start earning money to fuel your next big adventure.`
                        : `Your travel stories are valuable. Create your own travel blog in just one minute and share them with the world while earning money for your next adventure. Welcome to our travel community!`}
                    </p>
                    <SocialProof referrer={referrer} userCount={userCount} />
                    <a className="btn-solid-lg page-scroll" href="#join">
                      GET STARTED FOR FREE
                    </a>
                    <div className="tw-text-primary-100 tw-text-xs tw-pt-2">
                      No credit card required
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-block col-lg-6 col-xl-7">
                  <div className="image-container">
                    <div className="img-wrapper">
                      <div className="img-fluid">
                        <Image
                          priority
                          src={blogImg}
                          alt="alternative"
                          layout="responsive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <Image src={frameImg} className="frameImg" alt="" priority />
        <div className="cards-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="above-heading">Do What You Love</div>
                <h2 className="h2-heading">
                  You create amazing travel content. We make your blog fast,
                  beautiful and profitable.
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {[
                  {
                    title: 'Travel',
                    text:
                      'Embark on unforgettable journeys and capture the moments.',
                    image: travelImg,
                  },
                  {
                    title: 'Write',
                    text:
                      'Share your adventures with a global audience on TravelFeed.',
                    image: writeImg,
                  },
                  {
                    title: 'Earn',
                    text:
                      'Monetize your tales through ads, affiliate partnerships, and more.',
                    image: earnImg,
                  },
                ].map(({ title, text, image }) => {
                  return (
                    <div className="card" key={title}>
                      <div className="card-image container">
                        <div className="row h-100">
                          <div className="col-12 my-auto">
                            <Image
                              className="img-fluid"
                              src={image}
                              alt="alternative"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">{title}</h4>
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <FeatureTabs />
        <div id="details" className="basic-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="text-container">
                  <h2>
                    {userCount.toLocaleString()} Travelers Agree: TravelFeed is
                    the Place to Be!
                  </h2>
                  <p>
                    Starting a travel blog is exciting, but it can be tough when
                    no one reads your posts. It&apos;s frustrating to spend a
                    lot of time and effort creating something you love, only to
                    have no one see it.
                  </p>
                  <p>
                    With TravelFeed, your posts reach beyond your blog -
                    they&apos;re shared with our vast community of travelers.
                  </p>
                  <ul className="list-unstyled li-space-lg">
                    <li className="media">
                      <FontAwesomeIcon
                        className="pl-1 pt-2"
                        icon={faSquare}
                        color="#00695C"
                      />
                      <p className="media-body">
                        TravelFeed is a popular website, so your posts will show
                        up high in search results from day one
                      </p>
                    </li>
                    <li className="media">
                      <FontAwesomeIcon
                        className="pl-1 pt-2"
                        icon={faSquare}
                        color="#00695C"
                      />
                      <p className="media-body">
                        If your post is really good, our team might even feature
                        it for more people to see!
                      </p>
                    </li>
                    <li className="media">
                      <FontAwesomeIcon
                        className="pl-1 pt-2"
                        icon={faSquare}
                        color="#00695C"
                      />
                      <p className="media-body">
                        Use affiliate links in your posts to build a passive
                        income
                      </p>
                    </li>
                  </ul>
                  <a className="btn-solid-reg page-scroll" href="#join">
                    JOIN THE COMMUNITY
                  </a>
                </div>
              </div>
              <div className="col-lg-6 d-xl-block d-lg-block d-md-none d-sm-none d-none">
                <div className="image-container">
                  <Image
                    className="img-fluid"
                    src={communityImg}
                    alt="alternative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-pt-28" />
        {/* TODO: Remove padding and readd <Video /> */}
        <PricingTable referrer={referrer} />
      </div>
      <div id="join" className="tabs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="above-heading">Join TravelFeed</div>
              <h2 className="h2-heading">Get Started for Free</h2>
              <p className="p-heading">
                Start your travel blog in a minute - no credit card required.
              </p>
            </div>
            {hiveKeychain ? (
              <>
                <div className="col-lg-6">
                  <p>
                    Join TravelFeed using your Hive account - enter your
                    username below and click the Keychain button
                  </p>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: {
                            main: teal[600],
                          },
                        },
                      })}
                    >
                      <KeychainLogin
                        setHiveKeychain={setHiveKeychain}
                        disableAutoFocus
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </div>
                <div className="pt-5 col-lg-6 text-center hrright">
                  <h3 className="pb-2">No Hive account?</h3>
                  <div className="text-center pb-3">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setHiveKeychain(false)}
                    >
                      Sign up with a different method
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-6">
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: {
                            main: teal[600],
                          },
                        },
                      })}
                    >
                      <OnboardStart
                        autoFocus={false}
                        referrer={referrer}
                        isRoundBtn
                        isLightGrayBg
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </div>
                <div className="pt-5 col-lg-6 text-center hrright">
                  <h3 className="pb-2">Or sign up with</h3>
                  <div className="tw-max-w-md m-auto">
                    <LoginServices />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#F3F7FD' }}>
        <div className="frameImg">
          <Image src={bottomFrame} alt="" />
        </div>
      </div>
    </div>
  );
}
