/* eslint-disable react/no-unescaped-entities */
import { useRouter } from 'next/router';
import Join from '../../components/About/Join/Join';
import Footer from '../../components/General/Footer';
import Head from '../../components/Header/Head';
import Header from '../../components/Header/Header';
import withApollo from '../../lib/withApollo';

const JoinPage = () => {
  const router = useRouter();

  const passedRef = router.query.ref;

  return (
    <>
      <Head
        title="Join TravelFeed: Start your own travel blog"
        description="Start your own travel blog in 1 minute, publish amazing travel content, earn from your blog and keep traveling! Create amazing travel content. We make your blog fast, beautiful and profitable."
        seo={{ canonicalUrl: 'https://travelfeed.com/join' }}
      />
      <Header isScrollingNav isTransparentNavBar />
      <Join referrer={passedRef} />
      <div style={{ backgroundColor: '#00695C' }}>
        <Footer isLightFont isNoSpace secondCopyright="Template by Inovatik" />
      </div>
    </>
  );
};

export default withApollo(JoinPage);
